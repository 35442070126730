.headerStyle{
  text-align: center;
  width: 100%;
  height: 120;
}

.headerBlock {
  width: 100%;
  height: calc(100vh - (64px));
  padding-left: 100px;
  padding-right: 100px;
}

.bodyTextBadge{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.textBody {
  color: #00365f;
  font-family: 'Squad Semi Bold', Helvetica, sans-serif;
  font-size: 35px;
  font-weight: 800;
}

.badgeHover:hover{
  transition: transform .3s ease;
  transform: scale(1.1);
}

.containerDevices {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 600px) {
  .textBody {
    font-size: 25px !important;
    text-align: center;
    padding-left: 10px;
  }

  .headerBlock{
    padding-left: 10px;
    padding-right: 10px;
  }
}
