.containerDevices {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .stylingRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
  }
  
  .styleIcon {
    position: relative;
    top: 4px;
  }
  
  .styleName {
    color: #00365f;
    font-family: 'Squad Semi Bold', Helvetica, sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-left: 20px;
  }
  
  .styleWifi {
    position: relative;
    top: 4px;
    margin-left: auto;
    right: 10;
  }
  
  .diagonalLine {
    border-bottom: 1px solid rgb(0, 0, 0);
    width: 25px;
    position: absolute;
    top: -10;
    transform: rotate(45deg);
    transform-origin: left;
  }