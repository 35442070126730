.headerStyle {
  text-align: center;
  width: 100%;
}

.headerBlock {
  padding-top: 20px;
  height: calc(100vh - (64px));
}

.middleBlock {
  width: 80%;
  margin: auto;
}
