.DarkRed {
  border: 1px solid rgba(241, 45, 6, 0.81);
  background-color: rgba(150, 74, 64, 0.16);
  box-shadow: 0px 0px 2px #892715;
}

.TextRed {
  color: #99260f;
}

.Red {
  border: 1px solid rgba(241, 45, 6, 0.81);
  background-color: rgba(150, 74, 64, 0.16);
  box-shadow: 0px 0px 2px #892715;
}

.TextRed {
  color: #c72d0e !important;
}

.Orange {
  border: 1px solid rgba(241, 142, 6, 0.81);
  background-color: rgba(220, 128, 1, 0.16);
  box-shadow: 0px 0px 2px #ffb103;
}

.TextOrange {
  color: #f18f06 !important;
}

.Yellow {
  border: 1px solid rgba(241, 210, 6, 0.81);
  background-color: rgba(220, 216, 1, 0.16);
  box-shadow: 0px 0px 2px #fbff03;
}

.TextYellow {
  color: #f1d206 !important;
}

.LightGreen {
  border: 1px solid rgba(122, 132, 1, 0.46);
  background-color: rgba(122, 132, 1, 0.12156862745098039);
  box-shadow: 0px 0px 2px #7a8401;
}

.TextLightGreen {
  color: #798401 !important;
}

.DarkGreen {
  border: 1px solid rgba(122, 132, 1, 0.46);
  background-color: rgba(122, 132, 1, 0.12156862745098039);
  box-shadow: 0px 0px 2px #7a8401;
  color: #7a8401;
}

.TextDarkGreen {
  color: #798401 !important;
}

.LightBlue {
  border: 1px solid rgba(70, 159, 228, 0.81);
  background-color: rgba(0, 54, 95, 0.16);
  box-shadow: 0px 0px 2px #00365f;
}

.TextLightBlue {
  color: #1e89db !important;
}

.Blue {
  border: 1px solid rgba(0, 54, 95, 0.81);
  background-color: rgba(0, 54, 95, 0.16);
  box-shadow: 0px 0px 2px #00365f;
}

.TextBlue {
  color: #00365f !important;
}

.DarkBlue {
  border: 1px solid rgba(18, 37, 52, 0.81);
  background-color: rgba(0, 54, 95, 0.16);
  box-shadow: 0px 0px 2px #00365f;
}

.TextDarkBlue {
  color: #00365f !important;
}

.Purple {
  border: 1px solid rgba(55, 0, 95, 0.81);
  background-color: rgba(41, 0, 95, 0.16);
  box-shadow: 0px 0px 2px #3f005f;
}

.TextPurple {
  color: #37005f !important;
}
