.headerBlock {
  width: 100%;
}

.headerImage {
  margin-left: 40px;
  height: 46px !important;
  width: 150px !important;
}

.googlePlayButton{
  width: 150px;
}

.headerLogo {

}

.headerName {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  left: 30px;

}

.headerAvatar{
  display: flex;
  flex-direction: row;
  margin-right: 40px;
}

.sunIcon{
  color: #ffffff;
}

.sunIcon:hover{
  transition: transform .3s ease;
  transform: scale(1.1);
}

.moonIcon{
  color: #ffffff;
}

.moonIcon:hover{
  transition: transform .3s ease;
  transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
  .headerDevider {
    line-height: 50px;
    font-size: large;
  }

  .headerImage {
    height: 30px !important;
    width: 90px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .headerBlock {
    padding-top: 20px;
  }

  .headerImage {
    height: 80px !important;
    width: 250px !important;
  }

  .headerName {
    margin-top: 60px;
    font-size: 20px;
  }

  .headerAvatar {
    margin-top: 10px;
  }
}
