  .centerLoading{
      margin: auto;
  }
  
  .ldsellipsis {
    display: inline-block;
    position: relative;
    width: 40px;
    margin-right: 40px;
    height: 20px;
  }
  .ldsellipsis div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #f1f1f1;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    animation: ldsellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    animation: ldsellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    animation: ldsellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    animation: ldsellipsis3 0.6s infinite;
  }
  @keyframes ldsellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ldsellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ldsellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  