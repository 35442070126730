.headerBlock{
    width: 100%;
    display: flex;
    height: calc(100vh - (64px));
}

.middleBlock{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 55px;
}

.contentBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 2000px) {
    .collapseBlock{
        margin-top: 20px;
    }
}