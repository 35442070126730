.headerStyle {
  text-align: center;
  width: 100%;
  height: 150px;
  padding-inline: 0px;
}

.contentBlock {
  height: calc(100vh - (150px));
  overflow-x: hidden;
}

.btnRemoveMsg {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: #7a8401;
}

.btnRemoveMsg:hover {
  background-color: #00365f !important;
}

.imageBlock{
  
}

@media only screen and (min-width: 2000px) {
  .contentText {
    padding-top: 20vh;
    font-size: 150px;
  }

  .contentItems {
    padding-top: 20vh;
  }

  .item1 {
    width: 10vw;
    height: 10vw;
    line-height: 10vw;
    font-size: 30px;
    font-weight: 400;
  }

  .item2 {
    width: 10vw;
    height: 10vw;
    line-height: 10vw;
    font-size: 30px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 600px) {
  .textAlignCenter {
    width: 100%;
  }

  .contentDevider {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }

  .contentItems {
    width: 100%;
    padding-top: 50px;
  }

  .marginPlayButton {
    margin-top: 0px;
  }

  .contentText {
    font-size: 60px;
    padding-left: 0px;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .content-items {
    padding-top: 10vh;
  }

  .content-devider {
    width: 100%;
    flex-direction: column;
  }

  .item1 {
    width: 35vw;
    height: 35vw;
    line-height: 35vw;
    font-size: 20px;
    margin-left: 20vw;
  }

  .item2 {
    width: 35vw;
    height: 35vw;
    line-height: 35vw;
    font-size: 20px;
    margin-left: 40vw;
  }
}
