.headerStyle {
  text-align: center;
  width: 100%;
  height: 64px;
}

.headerBlock {
  height: calc(100vh - (64px));
  width: 100%;
  padding-top: 55px;
}

.content {
  margin-top: 50px;
  height: 400px;
  width: 80%;
  margin: auto;
}

.centerForm {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
}

@media only screen and (max-width: 600px) {
}
