.bodyLay {
  width: 85%;
  margin: auto;
}

.bodyTextBadge {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.textBody {
  color: #00365f;
  font-family: 'Squad Semi Bold', Helvetica, sans-serif;
  font-size: 35px;
  font-weight: 800;
}

.paneEdit {
  border-radius: 50px;
  width: 100%;
}

.paneRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 0.2em solid #f0f0f0;
}

.itemsModule {
  width: 50%;
}

.propsModule {
  width: 50%;
  height: 55vh;
  overflow-y: scroll;
  padding-right: 10px;
}

.groupSensors {
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: wrap;
  break-after: always;
  gap: 10px;
  width: 100%;
}



.btnSensor {
  width: 48%;
  text-align: start;
  height: 50px;
  margin: auto;
}

.btnSensorLast {
  margin-left: 10;

}

.btnSensor:nth-child(3n) {
  break-after: always;
}

.btnGroup {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}
