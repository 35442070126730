/* @import './../../Styles/notification-lines-colors.css'; */

.notificationBlock {
  width: 97%;
  height: 40px;
  padding: 60px;
  transition: 0.5s;
  margin: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.image {
  margin-top: 0px;
  width: 128px;
  margin-right: 5vw;
}

.imgSize {
  position: relative;
  width: 128px;
}

.moduleName{
  margin-right: 0px;
  width: 30vw;
}

.moduleNameStyle{
  font-size: 40px;
  font-weight: 00;
  font-family: "Squad Semi Bold", Helvetica, sans-serif;
}

.moduleMessage{

}
