.headerBlock {
    width: 100%;
    height: 200px;
  }

  .columnNames{
    display: flex;
    font-size: 40px;
    font-weight: bold;
    color: #7A8401;
    padding-top: 50px;
    height: 200px;
    position: relative;
  }

  .columnSpace{
    width: calc(150px + 9vw) ;
  }

  .columnMachine{
    justify-content:left;
    text-align: left;
    width: 30vw;
  }

  .columnMessage{
    text-align: left;
  }

  .columnSetting{
    position: absolute;
    right: 100px;
  }

  .btnSetting{
    font-size: 50px;
    size: 50px;
    color: #7A8401;
  }
  .btnSetting:hover{
    color: #393e00;
  }